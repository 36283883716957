import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Header, Home, TermsAndCondition, PrivacyPolicy, Offer } from './';
import { useTranslation } from 'react-i18next';


function usePageTitle() {
  const location = useLocation();

  useEffect(() => {
    const pageTitles = {
      '/enquire-now': 'Register',
      '/terms-and-conditions': 'Terms And Conditions',
      '/privacy-policy': 'Privacy Policy',
      '/offer': 'Offer',
    };

    document.title = `Metaluxe | ${pageTitles[location.pathname] || 'Unknown Page'}`;
  }, [location]);
}

function Layout({ isHeaderColorActive }) {
  const { t, i18n: { changeLanguage } } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(() => {
    const storedLanguage = localStorage.getItem('language');
    return storedLanguage ? storedLanguage : 'en';
  });

  const handleChangeLanguage = () => {
    const newLanguage = currentLanguage === "en" ? "zh" : "en";
    setCurrentLanguage(newLanguage);
    localStorage.setItem('language', newLanguage);
    changeLanguage(newLanguage);
  }

  useEffect(() => {
    changeLanguage(currentLanguage);
  }, [currentLanguage]);

  return (
    <>
      <Header isHeaderColorActive={isHeaderColorActive} t={t} currentLanguage={currentLanguage} handleChangeLanguage={handleChangeLanguage} />
      <Routes>
        <Route path="/" element={<Home t={t} currentLanguage={currentLanguage} />} />
        {/* <Route path="/enquire-now" element={<Register />} /> */}
        <Route path="/terms-and-conditions" element={<TermsAndCondition t={t} />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy t={t} />} />
        <Route path="/offer" element={<Offer t={t} />} />
      </Routes>
    </>
  );
}

export default Layout;
